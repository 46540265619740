function parseYouTubeDuration(duration) {
  //console.log(duration);
  var a = duration.match(/\d+/g);
  var timeString = "";

  if (
    duration.indexOf("M") >= 0 &&
    duration.indexOf("H") == -1 &&
    duration.indexOf("S") == -1
  ) {
    a = [a[0], "00"];
  }

  if (duration.indexOf("H") >= 0 && duration.indexOf("M") == -1) {
    a = [a[0], "00", a[1]];
  }
  if (
    duration.indexOf("H") >= 0 &&
    duration.indexOf("M") == -1 &&
    duration.indexOf("S") == -1
  ) {
    a = [a[0], "00", "00"];
  }

  duration = 0;

  for (let i = 0; i < a.length; i++) {
    a[i] = a[i].length < 2 ? "0" + a[i].toString() : a[i];
  }
  if (a.length == 3) {
    duration = duration + parseInt(a[0]) * 3600;
    duration = duration + parseInt(a[1]) * 60;
    duration = duration + parseInt(a[2]);
    timeString =
      a[2] !== 0 ? a[0] + ":" + a[1] + ":" + a[2] : a[0] + ":" + a[1];
    //console.log(duration, a);
  }

  if (a.length == 2) {
    duration = duration + parseInt(a[0]) * 60;
    duration = duration + parseInt(a[1]);
    timeString = a[0] + ":" + a[1];
    // timeString =
    //   a[1] !== 0 ? a[0] + ":" + a[1] : a[0] + ":" + a[1];
  }

  if (a.length == 1) {
    duration = duration + parseInt(a[0]);
    timeString = 0 + ":" + a[0];
  }
  //return duration;
  return timeString;
}

function millisecondsToTime(milli) {
  var seconds = Math.floor((milli / 1000) % 60);
  var minutes = Math.floor((milli / (60 * 1000)) % 60);

  return minutes + ":" + seconds;
}

exports.parseYouTubeDuration = parseYouTubeDuration;
exports.millisecondsToTime = millisecondsToTime;

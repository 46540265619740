import React, { useEffect, useState, useContext } from "react";
import { Context } from "../../context";
import LikeBtn from "../LikeBtn";
import DeleteBtn from "../DeleteBtn";
import ShareBtn from "../ShareBtn";
import CommentBtn from "../CommentBtn";
import VerifiedAliveIcon from "../../img/verified-alive-icon.png";

const FlexQuotes = ({ quote, likeBtn, deleteBtn }) => {
  const { localQuotesStorage } = useContext(Context);
  const [localQuotes /*setLocalQuotes*/] = localQuotesStorage;
  const { user } = useContext(Context);
  const [userData /*setUserData*/] = user;
  const [likeClass, setLikeClass] = useState("");
  const [clicked, setClicked] = useState(false);
  const onQuoteClick = (e) => {
    if (!deleteBtn) {
      const className = likeClass === "liked" ? "" : "liked";
      setLikeClass(className);
      // setClicked(true);
    }
  };
  useEffect(() => {
    let isMounted = true;
    const userId = userData.user ? userData.user.id : 0;
    const likesIDs = quote.likes.map((like) => like.user);
    // console.log(likesIDs);
    if (likesIDs.indexOf(userId) >= 0) {
      // console.log(quote);
      if (isMounted) setLikeClass("liked");
      //console.log(": ", quote.quote);
    }
    if (!userData.user) {
      const quotesIDs = localQuotes.map((quote) => quote._id);
      if (quotesIDs.indexOf(quote._id) >= 0) {
        // console.log(quote);
        if (isMounted) setLikeClass("liked");
        //console.log(": ", quote.quote);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [quote, userData.user]);

  return (
    <div
      className={`quote-item ${likeClass}`} /*onClick={(e) => onQuoteClick(e)}*/
    >
      <div className="quote-item-body">
        <div className="quote-item-body-author">
          <div className="quote-item-body-author-image">
            <img
              src={quote.author.image || quote.author.wikiImage}
              alt="author images"
            />
          </div>
          <p className="quote-item-body-author-name">{quote.author.name} </p>

          <img
            className="verified-icon"
            src={VerifiedAliveIcon}
            alt="author verified icon"
          />
        </div>
        <p>{quote.quote} </p>
      </div>
      <div className="quote-item-footer">
        {/* <CommentBtn /> */}
        {likeBtn ? (
          <div className="quote-item-footer-like">
            <LikeBtn
              quote={quote}
              liked={likeClass}
              clicked={clicked}
              onQuoteClick={onQuoteClick}
            />
            {/* <span>{quote.likes && quote.likes.length} </span> */}
          </div>
        ) : (
          ""
        )}
        {deleteBtn ? <DeleteBtn quote={quote} /> : ""}
        {/* <ShareBtn /> */}
      </div>
    </div>
  );
};

export default FlexQuotes;

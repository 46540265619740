import React, { useEffect, useState } from "react";
import FlexQuotes from "./FlexQuotes";
//import useWindowDimensions from "../hooks/useWindowDimensions";
const FlexQuotesContainer = ({ quotes, likeBtn, deleteBtn }) => {
  const [cardCount, setCardCount] = useState(18);
  const [loadQuotes, setLoadQuotes] = useState([]);
  //const { height, width } = useWindowDimensions();
  //const height = `${(quotes.length / 3) * 150}px`;
  //const [totalLength, setTotalLength] = useState(0);

  //const [containerHeight, setContainerHeight] = useState(0);
  // const [delta, setDelta] = useState(0);

  // useEffect(() => {
  //   // setDelta(1440 - width);
  //   // setContainerHeight(totalLength / 3 + (quotes.length * 93) / 3);
  // }, [width]);

  useEffect(
    () => {
      // const totalLettersInQuotes = quotes.length
      //   ? quotes.map((quote) => quote.quote.length).reduce((a, b) => a + b)
      //   : 0;
      // setTotalLength(totalLettersInQuotes);
      // setContainerHeight(totalLettersInQuotes / 3 + (quotes.length * 143) / 3);
    },
    [
      /* quotes */
    ]
  );
  useEffect(() => {
    //console.log(quotes.slice(0, cardCount));
    setLoadQuotes(quotes.slice(0, cardCount));
    //console.log(quotes.length, loadQuotes.length);
  }, [quotes, cardCount]);
  return (
    <div className="quotes">
      {/* <h1>Quotes</h1> */}

      <div
        // style={{ height: (containerHeight + delta).toString() + "px" }}
        className="quote-container"
      >
        {loadQuotes.map((quote, index) => (
          <FlexQuotes
            key={index}
            quote={quote}
            likeBtn={likeBtn}
            deleteBtn={deleteBtn}
          />
        ))}
      </div>
      {loadQuotes.length !== quotes.length && (
        <button onClick={() => setCardCount(cardCount + 6)} className="btn">
          Load more...
        </button>
      )}
    </div>
  );
};

export default FlexQuotesContainer;

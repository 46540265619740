import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
//import axios from "axios";
import FlexQuotesContainer from "./FlexQuotesContainer";
import { Context } from "../../context";
import Quotesletter from "./Quotesletter";

const Quotes = () => {
  const { user } = useContext(Context);
  const [userData /*setUserData*/] = user;
  const location = useLocation();
  const { localQuotesStorage } = useContext(Context);
  const [localQuotes /*setLocalQuotes*/] = localQuotesStorage;

  useEffect(() => {
    async function fetchData() {
      try {
        //const quotes = (await axios.get("/api/quotes")).data;
        //console.log(quotes);
        //console.log("local: ", localQuotes);
        // setAuthorNames(names);
      } catch (err) {
        console.error(err);
      }
    }
    fetchData();
    window.scrollTo(0, 0);
  }, [localQuotes, location]);

  return (
    <div className="myquotes">
      {/* {!userData.user && (
        <div className="myquotes-header">
          <h1>Sign up now and save your quotes to your Bucket</h1>
          <Link to="/sign-in" className="btn">
            Sign Up
          </Link>
        </div>
      )} */}
      <h1 style={{ textAlign: "center", margin: 0, marginBottom: "0.5em" }}>
        When you subscribe, your quotes will be saved.{" "}
      </h1>
      <Quotesletter header="Save your quotes and get a daily reminder!" />
      <FlexQuotesContainer quotes={localQuotes} deleteBtn={true} />
      {/* <div className="myquotes-recommended-quotes" id="recommended-quotes">
        <h1>More Quotes You May Like</h1>
        {userData.user && (
          <FlexQuotesContainer
            quotes={userData.user.recommendedQuotes}
            likeBtn={true}
          />
        )}
      </div> */}
    </div>
  );
};

export default Quotes;

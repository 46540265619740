import React, { useEffect, useState, useContext } from "react";
import { Context } from "../context";
import axios from "axios";

const LikeBtn = ({ quote, liked, clicked, onQuoteClick }) => {
  const { user } = useContext(Context);
  const [userData /*setUserData*/] = user;
  const { localQuotesStorage } = useContext(Context);
  const [localQuotes, setLocalQuotes] = localQuotesStorage;
  const [likeClass, setLikeClass] = useState(["hidden", ""]);

  const onLike = async () => {
    let isLiked = likeClass[0] === "hidden" ? true : false;
    const className = isLiked ? ["", "hidden"] : ["hidden", ""];
    setLikeClass(className);
    onQuoteClick();
    //   setLikeClass(className);
    // let isLiked = likeClass[0] ? true : false;
    // if (quoteLiked) {
    //   const className = liked ? ["", "hidden"] : ["hidden", ""];
    //   setLikeClass(className);
    // } else {
    //   const className =
    //   isLiked ? ["", "hidden"] : ["hidden", ""];
    //   setLikeClass(className);
    // }

    if (!userData.token) {
      //console.log(": ", userData);
      if (isLiked) {
        const quotes = [...localQuotes, quote];
        setLocalQuotes(quotes);
        localStorage.setItem("quotes", JSON.stringify(quotes));
      } else {
        deleteQuote(true);
      }
    } else {
      // if user logged in, then send every liked quote to db
      const header = {
        headers: {
          "x-auth-token": userData.token,
        },
      };
      if (isLiked) {
        try {
          const likedQuote = await axios.put(
            `/api/quotes/like/${quote._id}`,
            {},
            header
          );
          const quotes = [...localQuotes, likedQuote.data];
          setLocalQuotes(quotes);
          // localStorage.setItem("quotes", JSON.stringify(quotes));
          //console.log(likedQuote.data);
        } catch (err) {
          console.log(err);
        }
      } else {
        // if unliked remove that quote from db
        // deleteQuote();
        deleteQuote();

        await axios.put(`/api/quotes/unlike/${quote._id}`, {}, header);
      }
    }
  };

  const deleteQuote = (isUpdateLocalStorage) => {
    const arrOfLocalQuotes = localQuotes.map((item) => item.quote);
    const unlikedQuoteIndex = arrOfLocalQuotes.indexOf(quote.quote);
    let quotes = [...localQuotes];
    quotes.splice(unlikedQuoteIndex, 1);
    //console.log(unlikedQuoteIndex, quotes);
    setLocalQuotes(quotes);
    if (isUpdateLocalStorage)
      localStorage.setItem("quotes", JSON.stringify(quotes));
  };

  // useEffect(() => {
  //   const userId = userData.user ? userData.user.id : 0;
  //   if (quote.likes.indexOf(userId) >= 0) {
  //     // console.log(quote);
  //     setLikeClass(["", "hidden"]);
  //     // console.log(quote);
  //   }
  // }, [userData.user]);
  useEffect(() => {
    // const className = liked ? ["", "hidden"] : ["hidden", ""];
    // setLikeClass(className);
    //onLike();
    // console.log(": ", liked ? true : false, liked);
  }, [liked]);
  useEffect(() => {
    const className = liked.length ? ["", "hidden"] : ["hidden", ""];
    setLikeClass(className);
    if (clicked) onLike();
  }, [liked]);
  return (
    <div className="like-btn" onClick={onLike}>
      {/* Liked icon */}
      <div className={likeClass[0]}>
        <i className="fas fa-heart"></i>
      </div>
      {/* Like icon */}
      <div className={likeClass[1]}>
        <i className="far fa-heart"></i>
      </div>
    </div>
  );
};

export default LikeBtn;

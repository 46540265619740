import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import AddQuote from "../AddQuote";
import AddAuthorVideo from "../AddAuthorVideo";
import SignIn from "../auth/signIn";
import { Context } from "../../context";
import "react-phone-number-input/style.css";
const Admin = () => {
  const [allQuotes, setAllQuotes] = useState();
  const [startupQuotes, setStartupQuotes] = useState();
  const [password, setPassword] = useState();
  const [authors, setAuthors] = useState();
  const { quotes } = useContext(Context);
  const { user } = useContext(Context);
  const [userData, setUserData] = user;
  //const [randomQuotes, setRandomQuotes] = quotes;
  //const [phone, setPhone] = useState();
  //const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      try {
        const quotes = await axios.get("/api/quotes");
        const sQuotes = quotes.data.filter((quote) =>
          quote.category.includes("startup")
        );
        const quoteAuthors = await axios.get("/test-request");
        if (isMounted) {
          setStartupQuotes(sQuotes);
          setAuthors(quoteAuthors.data);
        }
        // const newQuotes = await axios.get("/api/quotes/newly-added");
        // setNewlyAddedQuotes(newQuotes.data);
      } catch (err) {
        console.error(err);
      }
    }

    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  const onTryNow = async (e) => {
    e.preventDefault();
    try {
      //   const newsletter = await axios.post("/api/users/join-waitlist", {
      //     email,
      //   });
      //const loginUser = { email };
      // const loginRes = await axios.post(
      //   "/api/users/login-subscriber",
      //   loginUser
      // );
    } catch (err) {
      console.log(err.response.data);
    }
  };
  const updateQuote = async (quote) => {
    try {
      console.log(quote.quote);
      const res = await axios.put(`/api/quotes/update/${quote._id}`);
      console.log("Quote updated: ", res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const SignOut = () => {
    setUserData({
      token: undefined,
      user: undefined,
    });
    localStorage.setItem("auth-token", "");
  };
  return (
    <div>
      {!userData.user ? (
        <SignIn />
      ) : (
        <div className="admin">
          <p>
            Hello <b>{userData.user.username}</b>
          </p>
          <button onClick={SignOut}>Sign Out</button>

          <AddAuthorVideo />
          <AddQuote />
        </div>
      )}
      {/* <div className="imageslide">
        {authors &&
          authors.map((author) => (
            <img src={author.image} alt="author images" />
          ))}
      </div> */}
      {/* {allQuotes &&
        allQuotes.map((quote, index) => {
          if (quote.category.includes("startup"))
            return (
              <div>
                <FlexQuotes
                  key={index}
                  quote={quote}
                  likeBtn={true}
                  // deleteBtn={deleteBtn}
                />
                <button
                  onClick={() => updateQuote(quote)}
                  style={{ background: "#ccc", marginLeft: "5em" }}
                >
                  Update
                </button>
              </div>
            );
        })} */}
    </div>
  );
};
export default Admin;

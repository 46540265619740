import React, { useState, useContext } from "react";
import { Context } from "../context";
import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import TextTransition, { presets } from "react-text-transition";

const QuotesletterForm = ({ header, submitBtnValue }) => {
  const { user } = useContext(Context);
  const [userData /*setUserData*/] = user;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [radioBtnValue, setRadioBtnValue] = useState("phone");
  const [subscribeBtnValue, setSubscribeBtnValue] = useState(
    "Send Me Startup Quotes"
  );
  const [formData, setFormData] = useState({
    phone: undefined,
    email: undefined,
  });
  const { phone, email } = formData;
  const { alerts } = useContext(Context);
  const [, /*alert*/ setAlert] = alerts;
  const [index, setIndex] = React.useState(0);
  const { localQuotesStorage } = useContext(Context);
  const [localQuotes, setLocalQuotes] = localQuotesStorage;
  // const [quotes, setQuotes] = useState([]);
  const onChange = (e) => {
    setFormData({ phone: "", [e.target.name]: e.target.value });
  };
  const radioBtnChange = (e) => {
    const value = e.target.value;
    setRadioBtnValue(value);
    if (value === "phone") setFormData({ ...formData, email: "" });
    if (value === "email") setFormData({ ...formData, phone: "" });
    // console.log(formData[radioBtnValue].length);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    const subscriberQuotes = localQuotes.map((quote) => quote._id);
    //console.log(subscriberQuotes);

    try {
      const newsletter = await axios.post("/api/subscribers/register", {
        email: formData.email,
        number: formData.phone,
        quotes: subscriberQuotes,
      });
      setFormSubmitted(true);
      console.log(newsletter.data);
    } catch (err) {
      //setFormSubmitted(false);
      console.log(err.response.data);
      setAlert({
        message: err.response.data.msg || "Error",
        type: "danger",
      });
    }
  };
  const TEXTS = ["Quotes", "Lessons", "Advice", "Details", "Secrets"];
  React.useEffect(() => {
    let i = 0;
    const intervalId = setInterval(
      () => {
        //console.log(i, index, TEXTS.length);
        setSubscribeBtnValue(
          `${submitBtnValue ? submitBtnValue : "Send Me"} Startup ${TEXTS[i]}`
        );
        if (i >= TEXTS.length - 1) {
          setIndex(0);
          i = 0;
        } else setIndex((index) => index + 1);
        i++;
      },
      1500 // every 2 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  return (
    <>
      {!userData.user && (
        // <div className="quotesletter">
        <div className="quotesletter-form" onSubmit={(e) => onSubmit(e)}>
          {formSubmitted ? (
            <div className="form-submitted-notice">
              <p>
                Thank you! You’ve been subscribed to our daily quotes
                newsletter.{" "}
              </p>
            </div>
          ) : (
            <form className="form">
              <div className="quotesletter-form-signup">
                {radioBtnValue === "email" && (
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    //placeholder="Get handpicked inpirational quotes."
                    //value={email}
                    onChange={onChange}
                    required
                  />
                )}
                {radioBtnValue === "phone" && (
                  <PhoneInput
                    name="phone"
                    placeholder="Get handpicked inpirational quotes."
                    value={phone}
                    onChange={(value) =>
                      setFormData({
                        email: undefined,
                        phone: value || undefined,
                      })
                    }
                    defaultCountry="US"
                    international
                    required
                  />
                  // <input
                  //   type="tel"
                  //   name="phone"
                  //   placeholder="Enter your phone number"
                  //   value={phone}
                  //   onChange={onChange}
                  //   required
                  // />
                )}
                <input
                  type="submit"
                  className="btn"
                  value={subscribeBtnValue}
                  // disabled={formData[radioBtnValue].length === 0}
                />
              </div>
              <input
                type="radio"
                id="phone"
                name="tel_email"
                value="phone"
                checked={radioBtnValue === "phone"}
                onChange={(e) => radioBtnChange(e)}
              />
              <label htmlFor="phone">Text</label>
              <input
                type="radio"
                id="email"
                name="tel_email"
                value="email"
                onChange={(e) => radioBtnChange(e)}
              />
              <label htmlFor="email">Email</label>
              <br />
            </form>
          )}
        </div>
        // </div>
      )}
    </>
  );
};

export default QuotesletterForm;

// src/components/Headline.js
import React, { useState, useEffect, useContext, useRef } from "react";

const Headline = () => {
  const videoRef = useRef();

  useEffect(() => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play().catch((error) => {
          console.error("Failed to play video:", error);
        });
      }
    };

    playVideo(); // Try to autoplay

    // Add event listener for user interaction fallback
    document.addEventListener("click", playVideo);

    return () => {
      document.removeEventListener("click", playVideo);
    };
  }, []);

  return (
    <div className="hero min-h-screen bg-base-100">
      <div className="hero-content flex-col lg:flex-row">
        {/* <img
          src="https://img.daisyui.com/images/stock/photo-1635805737707-575885ab0820.jpg"
          className="max-w-sm rounded-lg shadow-2xl"
        /> */}

        <div>
          <h1 className="text-5xl font-bold">
            Get 3x More Out of YouTube <i className="fab fa-youtube"></i>
          </h1>
          <p className="py-6 text-xl">
            {/* YouTube is great but trying to remember stuff from it is hard, I get it!

            Not another YouTube notetaker, we know it, we don't like it either. That's why we built Lanter to capture insight */}
            {/* Remembering stuff from YouTube is hard, I get it! */}
            {/* So I built Lanter
            to help you to capture insightful moments with ease. */}
            {/* YouTube is great for learning but remembering stuff from it is hard. */}
            {/* "Enjoy YouTube without the hassle of note-taking. Lanter helps you capture highlights, get email reminders, and retain knowledge effortlessly." */}
            YouTube is great but taking notes is not fun, we get it!
            {/* ,we get it! */}
            {/* <br />
            <br /> */}{" "}
            So we built Lanter to help you <b>capture highlights</b>,{" "}
            <b>receive email reviews</b>, and <b>retain more knowledge. </b>
            {/* So we built Lanter that help you to <b>capture highlights</b>,{" "}
            <b>send email reviews</b>using spaced repetition, and{" "}
            <b>retain more knowledge. </b> */}
            {/* easily. */}
          </p>
          {/* <a
            className="btn btn-primary"
            href="https://chrome.google.com/webstore/detail/lanter-youtube-highlighte/dddkbkfbiikdebcdafdnjcimkooehmfb"
          >
            <i className="fab fa-chrome text-xl"></i> Get Chrome Extension
          </a> */}
          <a
            //className="btn btn-md border"
            className="btn btn-md bg-secondary"
            href="/#features"
          >
            <i className="fab fa-chrome text-xl"></i>
            See Features
            <i className="fas fa-arrow-down"></i>
          </a>{" "}
        </div>
        <video
          className="sm:max-w-2xl rounded-lg shadow-2xl"
          ref={videoRef}
          //width="60%"
          height="400em"
          //   muted
          //   playsInline
          //   autoPlay
          //   loop
          autoPlay
          loop
          muted
          playsInline
          onCanPlay={() => {
            videoRef.current.playbackRate = 1;
          }}
        >
          <source
            src="https://quotebucket-2023.s3.us-west-1.amazonaws.com/lanter-extension-demo-1.1.8.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default Headline;

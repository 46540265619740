import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";

import axios from "axios";
//import Autocomplete from "react-autocomplete";
import { Context } from "../context";
const AddQuote = () => {
  const [, /*selectedAuthorID*/ setSelectedAuthorID] = useState("");
  const [quote, setQuote] = useState("");
  const [quoteCategory, setQuoteCategory] = useState("");
  const [quoteDateType, setQuoteDateType] = useState("");
  const [sourceUrl, setSourceUrl] = useState("");
  const [sourceType, setSourceType] = useState("video");
  const [sourceTitle, setSourceTitle] = useState("");

  const [authorObj, setAuthorObj] = useState();
  const [authorName, setAuthorName] = useState("");
  const [authorNames, setAuthorNames] = useState([]);
  const [autocompleteAuthorsList, setAutocompleteAuthorsList] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState("#edf2f7");

  const { user } = useContext(Context);
  const [userData /*setUserData*/] = user;
  const { localQuotesStorage } = useContext(Context);
  const [, /*localQuotes*/ setLocalQuotes] = localQuotesStorage;
  const { alerts } = useContext(Context);
  const [, /*alert*/ setAlert] = alerts;

  const onPasteQuote = async () => {
    try {
      const copied = await navigator.clipboard.readText();
      setQuote(copied);
    } catch (err) {
      console.log(err);
    }

    // console.log(copied);
  };

  const handleAuthorSelect = (value) => {
    setAuthorObj(value);
    handleAuthorNameChange(value.name);
  };
  const handleAuthorNameChange = (value) => {
    // const value = e.target.value;
    console.log(": ", value);
    if (value) setAuthorName(value);
    const autocompleteArr = authorNames.filter(
      (author) => author.name.toLowerCase().includes(value.toLowerCase())
      // author.name.substr(0, value.length).toLowerCase() ===
      //   value.toLowerCase() ||
      // author.name.substr(0, value.length).toLowerCase() ===
      //   value.toLowerCase()
    );
    //console.log(autocompleteArr);
    if (autocompleteArr.length < 10)
      setAutocompleteAuthorsList(autocompleteArr);
    else setAutocompleteAuthorsList([]);
  };

  // const handleAuthorNameOnSelect = (val) => {
  //   handleAuthorNameChange(val);
  // };

  const onSubmit = async (e) => {
    e.preventDefault();

    // find selected author's index
    const authorIndex = authorNames
      .map((author) => author.name.toLowerCase())
      .indexOf(authorName.trim().toLowerCase());
    setSelectedAuthorID(authorIndex);
    //console.log(authorNames[authorIndex]._id);
    let author;
    if (!authorNames[authorIndex]) {
      // setAlert({
      //   message: "Please select authors in autocomplete list",
      //   type: "danger",
      // });
      author = {
        name: authorName,
        _id: null,
      };

      //return;
    } else author = authorNames[authorIndex];
    //console.log(authorNames[authorIndex]);
    console.log(author);
    try {
      const header = {
        headers: {
          "x-auth-token": userData.token,
        },
      };
      // if user adds new quote without signing up,
      //then add those quotes to localstorage and update localquotes state
      if (!userData.token) {
        setAlert({
          message: "Please sign in to add new quotes!",
          type: "danger",
        });
        return;
        // const quotes = [...localQuotes, { quote, author_id }];
        // setLocalQuotes(quotes);
        // localStorage.setItem("quotes", JSON.stringify(quotes));
      } else {
        // if user adds new quote, then send them to the server
        const newQuote = {
          quote,
          author,
          date: quoteDateType,
          category: quoteCategory,
          source: { type: sourceType, url: sourceUrl, title: sourceTitle },
        };
        //console.log(quote);
        const userId = userData.user ? userData.user.id : 0;
        const res = await axios.post("/api/quotes", newQuote, header);
        console.log("Quote added: ", res.data);

        const quotes = await axios.get(`api/quotes/${userId}`);
        //console.log(userId, " ", quotes.data);
        setLocalQuotes(quotes.data);
        setAlert({
          message: "Quote has been added",
        });
      }
    } catch (err) {
      console.log("err: ", err);
    }

    setQuote("");
    //setAuthorName("");
    // setSourceUrl("");
    // setSourceTitle("");
  };

  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      try {
        const names = (await axios.get("/api/authors")).data;
        //console.log(names);
        if (isMounted) setAuthorNames(names);
      } catch (err) {
        console.error(err);
      }
    }
    // const requestQuote = async () => {
    //   const randomQuotes = await axios.get("/api/quotes/");
    //   if (isMounted) setRandomQuotes(randomQuotes.data);
    // };
    fetchData();
    // requestQuote();

    return () => {
      isMounted = false;
    };
  }, []);

  const inputStyle = {
    // display: "block",
    width: "100%",
    padding: "0.8em",
    fontSize: "1rem",
    border: "none",
    borderRadius: "10px",
    background: backgroundColor,
    outline: "none",
  };

  return (
    <div className="add-quote">
      <h2>Add Quote</h2>
      <form onSubmit={onSubmit}>
        {/* {quote.length === 0 && (
          <div className="add-quote-paste-btn" onClick={onPasteQuote}>
            Paste
            <i className="fas fa-caret-down"></i>
          </div>
        )} */}
        <label htmlFor="quote">Quote</label>
        <br />
        <textarea
          type="text"
          placeholder="Paste your quote"
          name="quote"
          value={quote}
          onChange={(e) => setQuote(e.target.value)}
          required
          // contentEditable="true"
          // readOnly="false"
        />
        <br />
        <label htmlFor="quote-date">Quote Date</label>
        <br />
        <select
          name="quote-date"
          id="quote-date"
          onChange={(e) => setQuoteDateType(e.target.value)}
          //onChange={(e) => console.log(e.target.value)}
        >
          <option value="">current date</option>
          <option value="random">random date</option>
        </select>
        <br />
        <label htmlFor="author">Quote Category</label>
        <br />

        <select
          name="quote-categories"
          id="quote-categories"
          onChange={(e) => setQuoteCategory(e.target.value)}
        >
          <option value="">general</option>
          <option value="startup">startup</option>
        </select>
        <br />
        <label htmlFor="author">Quote Source</label>
        <br />
        <select
          name="source-type"
          id="source-type"
          onChange={(e) => setSourceType(e.target.value)}
        >
          <option value="video">video</option>
          <option value="startup">article</option>
          <option value="">none</option>
        </select>
        <br />
        <input
          type="text"
          placeholder="Source URL"
          value={sourceUrl}
          onChange={(e) => setSourceUrl(e.target.value)}
          // required
        />
        <br />
        <input
          type="text"
          placeholder="Source Title"
          value={sourceTitle}
          onChange={(e) => setSourceTitle(e.target.value)}
          // required
        />
        <br />
        <label htmlFor="author">Author</label>
        <br />
        {/* <Autocomplete
          getItemValue={(item) => item.name}
          items={autocompleteAuthorsList}
          inputProps={{
            style: inputStyle,
            placeholder: "Enter author name",
            required: true,
            onSelect: () => setBackgroundColor("rgba(171, 206, 203, 0.5)"),
            onBlur: () => setBackgroundColor("#edf2f7"),
          }}
          menuStyle={{
            // position: "absolute",
            top: "16.5em",
            left: "1em",
            background: "#fff",
            padding: "0.1em",
            border: "none",
            borderRadius: "20px",
            boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
          }}
          wrapperStyle={{ width: "100%" }}
          renderItem={(item, isHighlighted) => (
            <div
              key={item._id}
              style={{
                background: isHighlighted ? "#edf2f7" : "white",
                paddingTop: "6px",
                borderRadius: "20px",
              }}
            >
              {item.name}
            </div>
          )}
          value={authorName}
          onChange={(e) => handleAuthorNameChange(e.target.value)}
          onSelect={(val) => handleAuthorNameChange(val)}
        /> */}

        <Select
          value={authorObj}
          onChange={(val) => handleAuthorSelect(val)}
          isSearchable
          //isClearable
          onInputChange={(val) => handleAuthorNameChange(val)}
          getOptionLabel={(option) => `${option.name}`}
          getOptionValue={(option) => `${option["name"]}`}
          options={autocompleteAuthorsList}
          closeMenuOnSelect
        />
        <br />
        {/* <div className="landing-form-reminder-priority">
              <p>Select the reminder priority:</p>
              <input
                type="radio"
                id="frequently"
                name="priority"
                value="Frequently"
              />
              <label htmlFor="frequently">Frequently</label>
              <input
                type="radio"
                id="occasionally"
                name="priority"
                value="occasionally"
                defaultChecked
              />
              <label htmlFor="occasionally">Occasionally</label>
              <input type="radio" id="rarely" name="priority" value="rarely" />
              <label htmlFor="rarely">Rarely</label>
              <br />
            </div>
            <br /> */}
        {/* <label htmlFor="email">Email</label>
            <br />
            <input
              type="email"
              placeholder="Email"
              name="email"
              // value={quote}
              // onChange={(e) => setQuote(e.target.value)}
              required
            /> */}
        <input type="submit" value="Add Quote" />
      </form>
    </div>
  );
};

export default AddQuote;

function getTimeSince(date) {
  var aDay = 24 * 60 * 60 * 1000;
  //let date = new Date(dt - aDay);
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;
  if (interval > 1) {
    // if (interval < 2) return Math.floor(interval) + " year";
    return Math.floor(interval) + "y";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    //if (interval < 2) return Math.floor(interval) + " month";
    return Math.floor(interval) + "mo";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    //if (interval < 2) return Math.floor(interval) + " day";
    return Math.floor(interval) + "d";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    //if (interval < 2) return Math.floor(interval) + " hour";
    return Math.floor(interval) + "h";
  }
  interval = seconds / 60;
  if (interval > 1) {
    //if (interval < 2) return Math.floor(interval) + " minute";
    return Math.floor(interval) + "m";
  }
  return Math.floor(seconds) + "s";
  //return "1m";

  // if (interval > 1) {
  //   if (interval < 2) return Math.floor(interval) + " year";
  //   return Math.floor(interval) + " years";
  // }
  // interval = seconds / 2592000;
  // if (interval > 1) {
  //   if (interval < 2) return Math.floor(interval) + " month";
  //   return Math.floor(interval) + " months";
  // }
  // interval = seconds / 86400;
  // if (interval > 1) {
  //   if (interval < 2) return Math.floor(interval) + " day";
  //   return Math.floor(interval) + " days";
  // }
  // interval = seconds / 3600;
  // if (interval > 1) {
  //   if (interval < 2) return Math.floor(interval) + " hour";
  //   return Math.floor(interval) + " hours";
  // }
  // interval = seconds / 60;
  // if (interval > 1) {
  //   if (interval < 2) return Math.floor(interval) + " minute";
  //   return Math.floor(interval) + " minutes";
  // }
  // // return Math.floor(seconds) + " seconds";
  // return "1 minute";
}

exports.getTimeSince = getTimeSince;

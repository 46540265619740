import React, { useEffect, useState, useContext } from "react";
import ReactStars from "react-rating-stars-component";
import { Context } from "../../context";
import axios from "axios";
const Review = () => {
  const [reviews, setReviews] = useState();
  const { alerts } = useContext(Context);
  const [, /*alert*/ setAlert] = alerts;
  const [starsKey, setStarsKey] = useState(Math.random());
  const [formData, setFormData] = useState({
    review: "",
    rating: 0,
    name: "",
  });
  const { review, rating, name } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    //console.log(formData);
    // if (userData.user)
    if (rating !== 0)
      try {
        setAlert({
          message: "Review added",
          type: "success",
        });
        // const config = {
        //   headers: {
        //     "Content-Type": "application/json",
        //     "x-auth-token": userData.token,
        //   },
        // };
        const allReviews = await axios.post("/api/reviews", formData);
        //console.log(allReviews.data);
        setReviews(allReviews.data);
        setFormData({
          review: "",
          rating: 0,
          name: "",
        });
        setStarsKey(Math.random());
        //console.log(formData);
      } catch (err) {
        console.log(err);
      }
    else
      setAlert({
        message: "Please Select Rating",
        type: "danger",
      });
  };
  useEffect(() => {
    let isMounted = true;
    const getPostsData = async () => {
      try {
        const res = await axios.get("/api/reviews");
        if (isMounted) setReviews(res.data);
        // console.log(getTimeSince(new Date(2020, 11, 15)));
      } catch (err) {
        console.log(err);
      }
    };
    getPostsData();
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <div className="reviews">
      <h1>Please leave us review!</h1>
      <div className="reviews-review">
        <form className="form" onSubmit={onSubmit}>
          <input
            placeholder="Name"
            type="text"
            name="name"
            onChange={onChange}
            value={name}
            required
          />
          <ReactStars
            // count={5}
            key={starsKey}
            name="rating"
            onChange={(value) => setFormData({ ...formData, rating: value })}
            size={16}
            activeColor="#ffd700"
            value={rating}
          />
          {/* <label htmlFor="quote">Quote</label> */}
          <textarea
            type="text"
            placeholder="Write your review"
            name="review"
            value={review}
            onChange={onChange}
            required
          />

          <input type="submit" value="Submit" />
        </form>
      </div>
      <div className="user-reviews">
        <h1>Reviews & Ratings</h1>
        {reviews &&
          reviews.map((review, index) => (
            <div className="user-reviews-review" key={index}>
              <b>{review.name}</b>
              <ReactStars
                value={review.rating}
                size={13}
                activeColor="#ffd700"
                edit={false}
              />
              <p>{review.review}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Review;

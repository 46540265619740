import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "./Navbar";
import Headline from "./Headline";
import Features from "./Features";
import Pricing from "./Pricing";
import Footer from "./Footer";
// import AppStoreLogo from "../../img/app-store-logo.png";
// import PlayStoreLogo from "../../img/play-store-logo.png";

const Landing = () => {
  return (
    <div>
      <Navbar />
      <Headline />
      <Features />
      <Pricing />
      <Footer />
    </div>
  );
};
export default Landing;

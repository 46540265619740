import React, { useContext, useState } from "react";
import { Context } from "../context";
import axios from "axios";
const DeleteBtn = ({ quote }) => {
  const { user } = useContext(Context);
  const [userData /*setUserData*/] = user;
  const { localQuotesStorage } = useContext(Context);
  const [localQuotes, setLocalQuotes] = localQuotesStorage;
  const [hiddenClass, setHiddenClass] = useState("hidden");

  const onDelete = () => {
    setHiddenClass("");
    setTimeout(() => {
      setHiddenClass("hidden");
    }, 2000);
  };
  const deleteQuote = (isUpdateLocalStorage) => {
    const arrOfLocalQuotes = localQuotes.map((item) => item.quote);
    const unlikedQuoteIndex = arrOfLocalQuotes.indexOf(quote.quote);
    let quotes = [...localQuotes];
    quotes.splice(unlikedQuoteIndex, 1);
    // console.log(unlikedQuoteIndex, quotes);
    setLocalQuotes(quotes);
    if (isUpdateLocalStorage)
      localStorage.setItem("quotes", JSON.stringify(quotes));
  };

  const removeQuote = async () => {
    setHiddenClass("hidden");
    if (!userData.token) {
      deleteQuote(true);
    } else {
      const header = {
        headers: {
          "x-auth-token": userData.token,
        },
      };
      try {
        deleteQuote();
        console.log("delete");
        await axios.put(`/api/quotes/unlike/${quote._id}`, {}, header);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="delete-btn">
      <span onClick={onDelete}>
        <i className="far fa-trash-alt"></i>
        {/* <i className="fas fa-ellipsis-v"></i> */}
      </span>
      <div
        className={`delete-btn-confirm ${hiddenClass}`}
        onClick={removeQuote}
      >
        <i className="far fa-trash-alt"></i>
        <p>Remove from Bucket</p>
      </div>
    </div>
  );
};

export default DeleteBtn;

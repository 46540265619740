// src/components/Features.js
import React from "react";
import LanterScreenshotDemo1 from "../../../img/lanter-screenshot-demo-1.png";
import LanterScreenshotDemo2 from "../../../img/lanter-screenshot-demo-2.png";
import LanterScreenshotDemo3 from "../../../img/lanter-screenshot-demo-3.png";
import LanterScreenshotDemo4 from "../../../img/lanter-screenshot-demo-4.png";

const Features = () => {
  return (
    <section id="features" className="py-10 scroll-mt-10 bg-white w-full">
      <div className="container mx-auto text-center px-4">
        <h2 className="text-3xl font-bold text-gray-800 mb-10">Features</h2>
        <div className="space-y-20">
          {/* <div className=""> */}
          <div className="card lg:card-side bg-base-100 flex justify-center mb-16">
            <div className="card-body pb-1 lg:w-2/5 flex justify-center flex-col flex-none">
              <h3 className="card-title text-2xl font-semibold text-gray-800">
                Capture Any Moment
              </h3>
              <p className="text-start text-gray-600 mt-2">
                Capture snippets with embedded button right on YouTube page
              </p>
              <ul className="list-none text-left mt-4 h-full leading-8">
                <li>
                  <i className="far fa-regular fa-check-circle"></i> Capture a
                  highlight with 1-click embedded button
                </li>
                <li>
                  <i className="far fa-regular fa-check-circle"></i> Utilize
                  fast AI transcripion in any video, not YouTube captions
                </li>
                <li>
                  <i className="far fa-regular fa-check-circle"></i> Capture
                  only short snippets and current timestamp
                </li>
                <p className="text-gray-400 my-5">
                  {/* * Available in the Basic and Pro plan */}
                </p>
                <a
                  //className="btn btn-md border"
                  className="btn btn-md bg-secondary"
                  href="https://chrome.google.com/webstore/detail/lanter-youtube-highlighte/dddkbkfbiikdebcdafdnjcimkooehmfb"
                >
                  <i className="fab fa-chrome text-lg"></i> Try Free
                  <i className="fas fa-arrow-right"></i>
                </a>{" "}
              </ul>

              {/* <div className="card-actions mt-4">
                <a href="#" className="btn btn-primary">
                  Learn More
                </a>
              </div> */}
            </div>
            <figure className="lg:w-1/5 px-5">
              <img
                src={LanterScreenshotDemo1}
                alt="Quick Snippet Capture"
                // className="w-2/4 m-5 rounded-xl shadow-xl"
                className="m-5 rounded-xl shadow-xl"
              />
            </figure>
          </div>
          <div className="card lg:card-side bg-base-100 flex justify-center flex-col-reverse">
            <figure className="lg:w-1/5 px-5">
              <img
                src={LanterScreenshotDemo2}
                alt="Quick Snippet Capture"
                //className="w-2/5 m-5 rounded-xl shadow-xl"
                className="m-5 rounded-xl shadow-xl"
              />
            </figure>
            <div className="card-body pb-1 lg:w-2/5 flex justify-center flex-col flex-none ">
              <h3 className="card-title text-2xl font-semibold text-gray-800">
                Access Bookmarks Easily
              </h3>
              <p className="text-start text-gray-600 mt-2">
                Search, read and review bookmarks in a Tweet-like format
              </p>
              <ul className="list-none text-left mt-4 h-full leading-8">
                <li>
                  <i className="far fa-regular fa-check-circle"></i> Search and
                  find bookmarks with ease
                </li>
                <li>
                  <i className="far fa-regular fa-check-circle"></i> Read
                  bookmarks easily that are automaticaly divided into small
                  paragaphs
                </li>
                <li>
                  <i className="far fa-regular fa-check-circle"></i> Jump to
                  bookmark timestamps and play the video with a click of button
                </li>
                <p className="text-gray-400 my-5">
                  {/* * Available in the Basic and Pro plan */}
                </p>
                <a
                  //className="btn btn-md border"
                  className="btn btn-md bg-secondary"
                  href="https://chrome.google.com/webstore/detail/lanter-youtube-highlighte/dddkbkfbiikdebcdafdnjcimkooehmfb"
                >
                  <i className="fab fa-chrome text-lg"></i> Try Free
                  <i className="fas fa-arrow-right"></i>
                </a>{" "}
              </ul>
            </div>
          </div>
          <div
            id="feature-3"
            className="card lg:card-side bg-base-100 flex justify-center scroll-mt-16"
          >
            <div className="card-body pb-1 lg:w-2/5 flex justify-center flex-col flex-none">
              <h3 className="card-title text-2xl font-semibold text-gray-800">
                Get 30% More Out of Your Bookmarks
              </h3>
              <p className="text-start text-gray-600 mt-2">
                Receive periodic email reminders to review bookmarks using
                Spaced Repetition
              </p>
              <ul className="list-none text-left mt-4 h-full leading-8">
                <li>
                  <i className="far fa-regular fa-check-circle"></i> Receive
                  bookmark emails with a snapshot of the video and transcript
                </li>
                <li>
                  <i className="far fa-regular fa-check-circle"></i> Utilize
                  spaced -repetition algorithm to help you review when you need
                  it
                </li>
                <p className="text-gray-400 my-5">
                  * Available in the Basic and Pro plan
                </p>
                <a
                  //className="btn btn-md border"
                  className="btn btn-md bg-secondary"
                  href="/#pricing"
                >
                  See Plans
                  <i className="fas fa-arrow-down"></i>
                </a>{" "}
              </ul>
            </div>
            <figure className="lg:w-1/5 px-5">
              <img
                src={LanterScreenshotDemo3}
                alt="Quick Snippet Capture"
                //className="w-2/5 m-5 rounded-xl shadow-xl"
                className="m-5 rounded-xl shadow-xl"
              />
            </figure>
          </div>
          <div
            id="feature-4"
            className="card lg:card-side bg-base-100 flex justify-center flex-col-reverse scroll-mt-16"
          >
            <figure className="lg:w-2/5 px-5">
              <img
                src={LanterScreenshotDemo4}
                alt="Quick Snippet Capture"
                //className="w-9/12 m-5 rounded-xl rounded-l-3xl shadow-xl"
                className="m-5 rounded-xl shadow-xl"
              />
            </figure>
            <div className="card-body pb-1 lg:w-2/5 flex justify-center flex-col flex-none">
              <h3 className="card-title text-2xl font-semibold text-gray-800">
                Generate Full Transcripts in Minutes
              </h3>
              <p className="text-start text-gray-600 mt-2">
                Transcribe entire videos quickly and organize the text into
                readable sections
              </p>
              <ul className="list-none text-left mt-4 h-full leading-8">
                <li>
                  <i className="far fa-regular fa-check-circle"></i>{" "}
                  AI-Generated Transcript
                </li>
                <li>
                  <i className="far fa-regular fa-check-circle"></i> Easily
                  follow transcript with real-time highlighter
                </li>
                <li>
                  <i className="far fa-regular fa-check-circle"></i> Jump to any
                  timestamp by clicking any paragraph
                </li>
                <li>
                  <i className="far fa-regular fa-check-circle"></i>{" "}
                  Double-click to bookmark any segment
                </li>
                <p className="text-gray-400 my-5">
                  * Available in the Basic and Pro plan
                </p>
                <a
                  //className="btn btn-md border"
                  className="btn btn-md bg-secondary"
                  href="/#pricing"
                >
                  See Plans
                  <i className="fas fa-arrow-down"></i>
                </a>{" "}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;

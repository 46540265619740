function parseYouTubeView(view) {
  let parsedView = view;
  if (view) {
    if (view.length > 3 && view.length < 7) {
      let parsedNum = parseInt(view) / 1000;
      parsedView = parsedNum.toFixed(0) + "K";
      if (parsedNum.toFixed(0).length === 1)
        parsedView = parsedNum.toFixed(1) + "K";
    }
    if (view.length > 6 && view.length < 10) {
      let parsedNum = parseInt(view) / 1000000;
      parsedView = parsedNum.toFixed(0) + "M";
      if (parsedNum.toFixed(0).length === 1)
        parsedView = parsedNum.toFixed(1) + "M";
    } else if (view.length > 9) {
      let parsedNum = parseInt(view) / 1000000000;
      parsedView = parsedNum.toFixed(0) + "B";
      if (parsedNum.toFixed(0).length === 1)
        parsedView = parsedNum.toFixed(1) + "B";
    }
  }
  return parsedView;
}

exports.parseYouTubeView = parseYouTubeView;

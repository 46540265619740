import React, { useState, createContext, useEffect } from "react";
import axios from "axios";
export const Context = createContext();

export const Provider = (props) => {
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined,
    loading: true,
  });
  const [localQuotes, setLocalQuotes] = useState([]);
  const [randomQuotes, setRandomQuotes] = useState([]);
  const [alert, setAlert] = useState({
    message: "",
    type: "",
  });
  const [addQuoteDropdownActive, setAddQuoteDropdownActive] = useState(false);

  const checkLoggedIn = async () => {
    let token = localStorage.getItem("auth-token");
    if (token === null || !token) {
      localStorage.setItem("auth-token", "");
      token = "";
    }

    try {
      const tokenRes = await axios.post("/api/users/tokenIsValid", null, {
        headers: { "x-auth-token": token },
      });
      if (tokenRes.data) {
        const userRes = await axios.get("/api/users/user", {
          headers: { "x-auth-token": token },
        });
        // console.log("-----------------------------------------");
        //console.log(userRes.data);
        setUserData({
          token,
          user: userRes.data,
          loading: false,
        });
        return userRes.data;

        // let token = localStorage.getItem("auth-token");
        // if (token === null) {
        //   const quotes = JSON.parse(localStorage.getItem("quotes"));
        //   setLocalQuotes(quotes ? quotes : []);
        //   // console.log(quotes);
        // } else {
        //   // Get all Liked Quotes by User and Set up to Local Store
        //   const quotes = await axios.get(`api/quotes/${userRes.data.id}`);
        //   console.log(quotes);
        // }
      } else {
        setUserData({
          token: undefined,
          user: undefined,
          loading: false,
        });
      }
    } catch (err) {}
  };

  const getQuotesByUserId = async () => {
    try {
      //const userId = userData.user ? userData.user.id : 0;
      const userId = (await checkLoggedIn()).id;
      //console.log("userID ", userId);
      //console.log(" ", userData);
      //Get all Liked Quotes by User and Set up to Local Store
      const quotes = await axios.get(`api/quotes/${userId}`);
      // console.log(userId, " ", quotes.data);
      setLocalQuotes(quotes.data);
    } catch (err) {
      const quotes = JSON.parse(localStorage.getItem("quotes"));
      setLocalQuotes(quotes ? quotes : []);
      //   // console.log(quotes);
    }
  };
  useEffect(() => {
    checkLoggedIn();
    getQuotesByUserId();
  }, []);

  useEffect(() => {
    // Copying and Setting up Context Store from Localstorage
    // getQuotesByUserId();
  }, [userData.user]);
  return (
    <Context.Provider
      value={{
        /*professors: [profs, setProfs],*/ user: [userData, setUserData],
        localQuotesStorage: [localQuotes, setLocalQuotes],
        quotes: [randomQuotes, setRandomQuotes],
        alerts: [alert, setAlert],
        addQuoteDropdown: [addQuoteDropdownActive, setAddQuoteDropdownActive],
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

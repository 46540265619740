import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy for Lanter: YouTube Highlighter</h1>
      <p>Last updated: May 3, 2023</p>
      <p>
        This Privacy Policy describes how Lanter: YouTube Highlighter ("we,"
        "us," or "our") collects, uses, and discloses your personal information
        when you use our Chrome extension.
      </p>
      <p>
        By using Lanter: YouTube Highlighter, you agree to the collection and
        use of information in accordance with this policy.
      </p>
      <h2>Information Collection and Use</h2>
      <p>
        While using our Chrome extension, we may ask you to provide us with
        certain personally identifiable information that can be used to contact
        or identify you. This information may include, but is not limited to,
        your email address.
      </p>
      <p>
        We use your personal information to provide and improve our services,
        respond to your inquiries, and communicate with you about updates,
        promotions, and other relevant information.
      </p>
      <h2>Information Sharing</h2>
      <p>
        We do not sell, trade, or rent your personal information to others. We
        may share generic aggregated demographic information not linked to any
        personal identification information regarding visitors and users with
        our business partners, trusted affiliates, and advertisers for the
        purposes outlined above.
      </p>
      <h2>Log Data</h2>
      <p>
        We may also collect non-personally identifiable information that your
        browser sends whenever you use our Chrome extension. This log data may
        include information such as your computer's Internet Protocol (IP)
        address, browser type, browser version, the pages you visit, the time
        and date of your visit, and other statistics.
      </p>
      <h2>Cookies</h2>
      <p>
        Cookies are files with a small amount of data, which may include an
        anonymous unique identifier. Cookies are sent to your browser from a
        website and stored on your computer's hard drive.
      </p>
      <p>
        We use cookies to collect information in order to improve our services.
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Chrome extension.
      </p>
      <h2>Security</h2>
      <p>
        The security of your personal information is important to us, but
        remember that no method of transmission over the Internet or method of
        electronic storage is 100% secure. While we strive to use commercially
        acceptable means to protect your personal information, we cannot
        guarantee its absolute security.
      </p>
      <h2>Your Rights</h2>
      <p>
        You have the right to access, correct, update, or delete your personal
        information. You can do this by contacting us at the email address
        provided.
      </p>
      <h2>Changes to This Privacy Policy</h2>
      <p>
        We reserve the right to update or change our Privacy Policy at any time.
        You should check this Privacy Policy periodically. Your continued use of
        the Chrome extension after we post any modifications to the Privacy
        Policy on this page will constitute your acknowledgment of the
        modifications and your consent to abide and be bound by the Privacy
        Policy.
      </p>
      <h2>Google User Data</h2>
      <p>
        Our Chrome extension uses the OAuth 2.0 protocol for authentication and
        authorization with Google APIs. We access your Google account data,
        specifically your email address and profile information, when you
        authenticate with Google. This data is used to provide a personalized
        user experience and for the functional operation of our extension.
      </p>
      <p>
        We do not store, share, or sell your Google user data. Your Google user
        data is only kept in your browser's local storage and is removed once
        you log out or uninstall the extension. Our use of information received
        from Google APIs will adhere to Google API Services User Data Policy,
        including the Limited Use requirements.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at quotebucketinfo@gmail.com.
      </p>
    </div>
  );
};

export default PrivacyPolicy;

import React, { useState, useContext, useEffect } from "react";
import SignUp from "./signUp";
import { Context } from "../../context";
import { useHistory } from "react-router-dom";
import axios from "axios";
import EmailPhoneOption from "../EmailPhoneOption";
//import GoogleLogin from "react-google-login";

function SignIn(props) {
  const [radioBtnValue, setRadioBtnValue] = useState("phone");
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const { user } = useContext(Context);

  const [, /* userData */ setUserData] = user;
  const { localQuotesStorage } = useContext(Context);
  const [, /*localQuotes*/ setLocalQuotes] = localQuotesStorage;
  const history = useHistory();
  const [signInActiveClass, setSignInActiveClass] = useState([
    "",
    "sign-in-up-active",
  ]);
  const { alerts } = useContext(Context);
  const [, /*alert*/ setAlert] = alerts;

  const submit = async (e) => {
    e.preventDefault();

    try {
      //const loginUser = { email, phone, password };
      const loginUser = { username, password };
      const loginRes = await axios.post("/api/users/login", loginUser);
      setUserData({
        token: loginRes.data.token,
        user: loginRes.data.user,
      });
      console.log(loginRes.data);
      localStorage.setItem("auth-token", loginRes.data.token);
      const quotes = await axios.get(`api/quotes/${loginRes.data.user.id}`);
      // console.log(userId, " ", quotes.data);
      setLocalQuotes(quotes.data);
      // history.push("/");
    } catch (err) {
      console.log("err: ", err.response.data);
      err.response.data.msg &&
        setAlert({
          message: err.response.data.msg,
          type: "danger",
        });
    }
  };

  const handleSignIn = () => {
    setSignInActiveClass(["sign-in-up-active", ""]);
  };
  const handleSignUp = () => {
    setSignInActiveClass(["", "sign-in-up-active"]);
  };

  // const responseGoogle = (response) => {
  //   console.log(response);
  //   console.log(response.profileObj);
  // };
  const radioBtnChange = (e) => {
    const value = e.target.value;
    setRadioBtnValue(value);
    if (value === "phone") setEmail();
    if (value === "email") setPhone();
  };

  useEffect(() => {
    // if (history.location.state) {
    //   console.log(history.location.state);
    //       setRandomQuotes(history.location.state.data);
    // }
  }, []);
  return (
    <div className="sign-in-page">
      <div className="sign-in-sign-up-toggle">
        <div className={signInActiveClass[0]} onClick={handleSignIn}>
          <p>Sign In</p>
        </div>
        {/* <div className={signInActiveClass[1]} onClick={handleSignUp}>
          <p>Sign Up</p>
        </div> */}
      </div>
      {/* {signInActiveClass[0] === "sign-in-up-active" ? */}
      <div className="sign-in-container">
        <p>Sign in to your account.</p>

        {/* <GoogleLogin
            clientId="240445971538-ufg49pekkgqnjrc3i7j53thdmnd8j2oq.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
          /> */}
        <form onSubmit={submit}>
          {/* <label htmlFor="email">Email</label>
            <br />*/}
          <input
            type="text"
            placeholder="Username"
            required
            name="username"
            onChange={(e) => setUsername(e.target.value)}
          />
          {/* <EmailPhoneOption
              radioBtnChange={radioBtnChange}
              radioBtnValue={radioBtnValue}
              setEmail={setEmail}
              setPhone={setPhone}
              phone={phone}
            /> */}
          <br />
          <label htmlFor="password">Password</label>
          <br />
          <input
            type="password"
            // autoComplete="new-password"
            placeholder="Enter your password"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <br />
          <input
            type="submit"
            // autoComplete="new-password"
            value="Sign In"
            className="btn-primary"
          />
        </form>
      </div>
      {/* : ( // <SignUp />
      )} */}
    </div>
  );
}

export default SignIn;

import React, { Fragment, useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
/* Tailwind landing */
import Landing from "./components/layout/tailwind/Landing";
// import Landing from "./components/layout/Landing";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Pricing from "./components/layout/Pricing";
import Quotes from "./components/layout/Quotes";
import StartupQuotes from "./components/layout/StartupQuotes";
import Admin from "./components/layout/Admin";
import Author from "./components/layout/Author";
import AllAuthors from "./components/layout/AllAuthors";
import QuoteCategory from "./components/layout/QuoteCategory";
import MyQuotes from "./components/layout/MyQuotes";
import Review from "./components/layout/Review";
import SignIn from "./components/auth/signIn";
import Feedback from "./components/layout/Feedback";
import { Context } from "./context";
import QuotesletterForm from "./components/QuotesletterForm";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import Alert from "./components/misc/Alert";
import ReactGa from "react-ga";
// import Alert from "./components/layout/Alert";
// import setAuthToken from "./utils/setAuthToken";
// import { loadUser } from "./actions/auth";
// // Redux
// import { Provider } from "react-redux";
// import store from "./store";

// import "./App.css";
import "./Tailwind.css";

// if (localStorage.token) {
//   setAuthToken(localStorage.token);
// }

function App() {
  const { alerts } = useContext(Context);
  const [alert] = alerts;
  useEffect(() => {
    ReactGa.initialize("UA-179412951-2");

    // to report page views

    ReactGa.pageview("/");
  }, []);

  useEffect(() => {
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (window.location.href.lastIndexOf("#") > 0) {
      document.getElementById(href)?.scrollIntoView();
    }
  });
  return (
    <Router>
      {/* <Fragment> */}
      {/* <Navbar /> */}

      <section className="container">
        {alert.message.length > 0 && (
          <Alert
            message={alert.message}
            type={alert.type}
            // clearError={() => setError(undefined)}
          />
        )}
        <Switch>
          <Route exact path="/" component={Landing} />
          {/* <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} /> */}
          <Route path="/pricing" component={PricingRedirect} />
          {/* <Route exact path="/pricing" component={Pricing} /> */}
          <Route exact path="/reviews" component={Review} />
          <Route exact path="/quotes" component={Quotes} />
          <Route exact path="/myquotes" component={Quotes} />
          <Route exact path="/sign-in" component={SignIn} />
          <Route exact path="/quote-category" component={QuoteCategory} />
          {/* <Route exact path="/startup-quotes" component={StartupQuotes} /> */}
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/all-authors" component={AllAuthors} />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route exact path="/terms" component={TermsOfService} />
          <Route exact path="/:id" component={Author} />
        </Switch>
      </section>
      {/* <QuotesletterForm /> */}
      {/* <Feedback /> */}
      {/* <Footer /> */}
      {/* </Fragment> */}
    </Router>
  );
}
const PricingRedirect = () => {
  const history = useHistory();
  React.useEffect(() => {
    history.push("/#pricing");
  }, [history]);

  return null;
};
export default App;

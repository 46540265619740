import React, { useState, useContext } from "react";
import { Context } from "../../context";
import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import TextTransition, { presets } from "react-text-transition";
import QuotesletterForm from "../QuotesletterForm";

const Quotesletter = ({ header }) => {
  const { user } = useContext(Context);
  const [userData /*setUserData*/] = user;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [radioBtnValue, setRadioBtnValue] = useState("phone");
  const [subscribeBtnValue, setSubscribeBtnValue] = useState(
    "Send Me Startup Quotes"
  );
  const [formData, setFormData] = useState({
    phone: undefined,
    email: undefined,
  });
  const { phone, email } = formData;
  const { alerts } = useContext(Context);
  const [, /*alert*/ setAlert] = alerts;
  const [index, setIndex] = React.useState(0);
  const { localQuotesStorage } = useContext(Context);
  const [localQuotes, setLocalQuotes] = localQuotesStorage;
  // const [quotes, setQuotes] = useState([]);

  return (
    <>
      {!userData.user && (
        <div className="quotesletter">
          <div className="quotesletter-header">
            {/* <h2>
              <span> Not sure to sign up?</span> 
              Try our newsletter and get your daily inspirational quotes.
            </h2> */}
            <p>{header}</p>
          </div>
          <QuotesletterForm submitBtnValue="Send Me" />
        </div>
      )}
    </>
  );
};

export default Quotesletter;

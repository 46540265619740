import React, { useContext, useEffect } from "react";
import { Context } from "../../context";
const Alert = (props) => {
  const { alerts } = useContext(Context);
  const [, /*alert*/ setAlert] = alerts;

  // console.log("Success Alerted");
  useEffect(() => {
    setTimeout(() => {
      setAlert({ message: "" });
    }, 5000);
  }, []);
  return (
    <div
      className={props.type === "danger" ? "error-notice" : "success-notice"}
    >
      <span>{props.message}</span>
      <i
        className="fa fa-window-close"
        onClick={() => setAlert({ message: "" })}
      ></i>
    </div>
  );
};

export default Alert;

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Context } from "../../context";
import FlexQuotes from "./FlexQuotes";

const AllAuthors = () => {
  const [authorName, setAuthorName] = useState("");
  const [authorNames, setAuthorNames] = useState([]);

  const { quotes } = useContext(Context);
  const { user } = useContext(Context);
  const [userData, setUserData] = user;

  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      try {
        let authNames = (await axios.get("/api/authors")).data;
        // console.log(authNames);
        authNames = authNames.filter((author) => !author.mostFamous);
        // authNames = authNames.filter(
        //   (author) => !author.image && !author.wikiImage
        // );
        // authNames = authNames.map((author) =>
        //   author.name.toLowerCase().replaceAll(" ", "-")
        // );
        if (isMounted) setAuthorNames(authNames);
      } catch (err) {
        console.error(err);
      }
    }

    fetchData();
    // console.log(window.innerHeight);
    return () => {
      isMounted = false;
    };
  }, []);

  // const handleOnAuthorClick = async (author) => {
  //   try {
  //     const res = await axios.put("/api/authors/admin/test", {
  //       authorId: author._id,
  //     });
  //     const updatedAuthors = authorNames.filter(
  //       (auth) => auth._id !== author._id
  //     );
  //     setAuthorNames(updatedAuthors);
  //     console.log(res.data);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  return (
    <div className="all-authors">
      <b>Total Authors: {authorNames.length}</b>
      <br />
      <br />
      {/* {authorNames &&
        authorNames.map((author, index) => (
          <div key={index} style={{ width: "20%" }}>
            {author.name}
            <button onClick={() => handleOnAuthorClick(author)}>Click</button>
          </div>
        ))} */}
      {/*
      {authorNames &&
        authorNames.map((author, index) => (
          <div
            key={index}
            //className="quote-item-body-author-image"
            style={{ display: "flex", margin: "1em", width: "250px" }}
          >
            <div>{author} </div>{" "}
            <img
              src={`https://myquotebucket.s3.amazonaws.com/author-profile-images/${author}.jpg`}
              alt="author images"
              style={{ width: "50px" }}
            />
          </div>
        ))} */}
    </div>
  );
};
export default AllAuthors;

import React, { useEffect, useState } from "react";
import axios from "axios";
const QuoteCategory = () => {
  const [quotes, setQuotes] = useState([]);
  const [tags, setTags] = useState([]);
  const [activeTags, setActiveTags] = useState(0);
  const [tagsArr /* setTagsArr */] = useState([
    "Startup",
    "Sport",
    "Business",
    "Boxing",
    "Basketball",
    "Life",
    "Answers questions",
    "Inspirational",
    "Explains thouroughly",
    "Test heavy",
  ]);

  const onTagChange = (e) => {
    e.preventDefault();
    const className = e.target.className;
    const value = e.target.text;
    if (activeTags > 2 && className === "") return;
    if (className === "") {
      e.target.className = "active-tag";
      setActiveTags(activeTags + 1);
      setTags(tags.concat(value));
    } else {
      e.target.className = "";
      const arrTags = tags.filter((tag) => tag !== value);
      setActiveTags(activeTags - 1);
      setTags(arrTags);
    }
  };
  useEffect(() => {
    const getAllQuotes = async () => {
      try {
        const allQuotes = await axios.get("/api/quotes/");
        setQuotes(allQuotes.data);
        console.log(allQuotes);
      } catch (err) {
        console.error(err);
      }
    };

    getAllQuotes();
  }, []);
  return (
    <div>
      <h1>category</h1>
      {quotes.map((quote) => (
        <div className="quote-category-item">
          {quote.quote}
          <p>{quote.author.name}</p>
          <div className="tag-container">
            {tagsArr.map((tag, index) => (
              <a href="/#" onClick={onTagChange} key={index}>
                {tag}
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default QuoteCategory;

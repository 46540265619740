import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import AddQuote from "../AddQuote";
import SignIn from "../auth/signIn";
import { Context } from "../../context";
import SearchIcon from "../../img/search-icon.png";
import { useParams } from "react-router-dom";
import FlexQuotesContainer from "./FlexQuotesContainer";

import "react-phone-number-input/style.css";
const Author = () => {
  const params = useParams();
  const [authorQuotes, setAuthorQuotes] = useState();
  const [author, setAuthor] = useState();
  const { quotes } = useContext(Context);
  const { user } = useContext(Context);
  const [userData, setUserData] = user;

  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      try {
        const auth = (await axios.get(`/api/authors/author/${params.id}`)).data;
        const quotes = (await axios.get(`/api/authors/${params.id}`)).data;
        // console.log(auth);
        // console.log(quotes);
        if (isMounted) {
          setAuthorQuotes(quotes);
          setAuthor(auth);
        }
      } catch (err) {
        console.error(err);
      }
    }
    fetchData();
    window.scrollTo(0, 0);
    return () => {
      isMounted = false;
    };
  }, [params.id]);

  return (
    <div className="author">
      <h1 style={{ textAlign: "center" }}>{author && author.name}</h1>
      {authorQuotes && (
        <FlexQuotesContainer quotes={authorQuotes} likeBtn={true} />
      )}
    </div>
  );
};
export default Author;

import React from "react";

const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service for Lanter: YouTube Highlighter</h1>
      <p>Last updated: May 3, 2023</p>
      <p>
        Please read these Terms of Service ("Terms") carefully before using the
        Lanter: YouTube Highlighter Chrome extension ("the Extension") operated
        by us.
      </p>
      <p>
        Your access to and use of the Extension is conditioned on your
        acceptance of and compliance with these Terms. These Terms apply to all
        users who access or use the Extension.
      </p>
      <p>
        By accessing or using the Extension, you agree to be bound by these
        Terms. If you disagree with any part of the Terms, then you may not
        access the Extension.
      </p>
      <h2>Use of the Extension</h2>
      <p>
        You agree to use the Extension only for the purposes that are permitted
        by (a) these Terms and (b) any applicable law, regulation, or generally
        accepted practices or guidelines in the relevant jurisdictions.
      </p>
      <h2>Restrictions</h2>
      <p>
        You agree not to engage in any activity that interferes with or disrupts
        the Extension, or the servers and networks that are connected to the
        Extension. You may not reproduce, duplicate, copy, sell, trade, or
        resell the Extension for any purpose.
      </p>
      <h2>Termination</h2>
      <p>
        We may terminate or suspend your access to the Extension immediately,
        without prior notice or liability, for any reason whatsoever, including
        without limitation if you breach the Terms.
      </p>
      <p>
        Upon termination, your right to use the Extension will immediately
        cease. If you wish to terminate your use of the Extension, you may
        simply discontinue using it.
      </p>
      <h2>Changes to These Terms</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material, we will try to provide at
        least 30 days' notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole discretion.
      </p>
      <p>
        By continuing to access or use the Extension after those revisions
        become effective, you agree to be bound by the revised Terms. If you do
        not agree to the new Terms, you must stop using the Extension.
      </p>
      <h2>Third-Party Data</h2>
      <p>
        Our extension utilizes the OAuth 2.0 protocol for authentication and
        authorization with Google APIs. By using our extension, you agree to
        allow us to access your Google account data, specifically your email
        address and profile information. This data is used solely to provide a
        personalized user experience and for the functional operation of our
        extension. We do not store, share, or sell your Google user data. Our
        use of information received from Google APIs will adhere to the Google
        API Services User Data Policy, including the Limited Use requirements.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at
        quotebucketinfo@gmail.com.
      </p>
    </div>
  );
};

export default TermsOfService;

// src/components/Pricing.js
import React, { useState, useEffect, useContext, useRef } from "react";
const Pricing = () => {
  const [plan, setPlan] = useState("free");
  const [isMonthly, setIsMonthly] = useState(true);
  const [basicPlanUrl, setBasicPlanUrl] = useState(
    "https://buy.stripe.com/eVa6pM8DYbuWc7u148"
  );
  const [proPlanUrl, setProPlanUrl] = useState(
    "https://buy.stripe.com/28o3dA9I2dD40oM28d"
    // https://buy.stripe.com/test_eVa3do41l4Ye6KkcMN?prefilled_email=jenny%40example.com
  );

  return (
    <section id="pricing" className="py-10 scroll-mt-10 w-full mb-20">
      {/* <div className="container mx-auto text-center px-4"> */}
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-800">
          Pricing
        </h2>
        <div className="flex items-center justify-center border-gray my-8">
          <span
            className={isMonthly ? "text-gray-500" : "text-gray-900 font-bold"}
          >
            Monthly
          </span>
          <div className="relative mx-4">
            {/* <input
            type="checkbox"
            id="toggle"
            className="sr-only"
            //checked={isMonthly}
          />
          <div className="block bg-gray-500 w-24 h-8 rounded-full"></div>
          <div
            className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition transform"
            style={{
              transform: isMonthly ? "translateX(24px)" : "translateX(0)",
            }}
          ></div> */}
            <input
              type="checkbox"
              className="toggle [--tglbg:#e6e6e6] toggle-lg cursor-pointer border-none bg-black hover:bg-black-100"
              onChange={() => {
                setBasicPlanUrl(
                  !isMonthly
                    ? "https://buy.stripe.com/eVa6pM8DYbuWc7u148"
                    : "https://buy.stripe.com/bIY3dA9I2gPggnKaEK"
                );
                setProPlanUrl(
                  !isMonthly
                    ? "https://buy.stripe.com/28o3dA9I2dD40oM28d"
                    : "https://buy.stripe.com/8wMbK607s7eG4F2aEL"
                );
                setIsMonthly(!isMonthly);
              }}
              // checked
            />
          </div>
          <span
            className={isMonthly ? "text-gray-900 font-bold" : "text-gray-500"}
          >
            Yearly
          </span>
        </div>
        <div className="flex flex-wrap justify-center mt-10 space-y-4 md:space-y-0 md:space-x-4">
          <div className="card w-full lg:w-1/4 bg-gray-300 shadow-xl border">
            <div className="card-body">
              <h3 className="card-title text-xl font-semibold text-gray-800">
                Free Plan
              </h3>
              {/* <p className="text-gray-600 mt-2">
                Basic features for occasional use.
              </p> */}
              <p className="text-2xl font-bold text-gray-800 mt-4">$0</p>
              <ul className="list-none text-left mt-4 h-full">
                <li>
                  {" "}
                  <i className="fas fa-regular fa-check"></i> 10 Monthly
                  Bookmarking
                </li>
                <li className="text-gray-500">
                  <i className="fas fa-times mr-1.5"></i> Bookmark Email Reviews
                </li>
                <li className="text-gray-500">
                  <i className="fas fa-times mr-1.5"></i> Full Video
                  Transcription
                </li>
              </ul>
              <div className="card-actions justify-center mt-4">
                <a
                  href="https://chrome.google.com/webstore/detail/lanter-youtube-highlighte/dddkbkfbiikdebcdafdnjcimkooehmfb"
                  className={
                    plan === "free"
                      ? "btn btn-lg btn-wide justify-between rounded-2xl w-full"
                      : "btn btn-primary btn-lg btn-wide justify-between rounded-2xl w-full"
                  }
                >
                  {/* {plan === "free" ? "Current Plan" : "Select Plan"} */}
                  {/* {plan === "free" ? "Free" : "Select Plan"} */}
                  <div>
                    <i className="fab fa-chrome text-lg"></i> Try Free{" "}
                  </div>
                  <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="card w-full lg:w-1/4 bg-white shadow-xl border">
            <div className="card-body">
              <h3 className="card-title text-xl font-semibold text-gray-800">
                Basic Plan
              </h3>
              {/* <p className="text-gray-600 mt-2">
                Advanced features for power users.
              </p> */}
              {isMonthly ? (
                <p className="text-2xl font-bold text-gray-800 mt-2">
                  $12.99 <span className="text-lg font-normal">/ month</span>
                </p>
              ) : (
                <p className="text-2xl font-bold text-gray-800 mt-2">
                  $9.99{" "}
                  <span className="text-lg font-normal">
                    / month, billed annually
                  </span>
                </p>
              )}
              <ul className="list-none text-left mt-4 h-full">
                <li>
                  <i className="fas fa-regular fa-check"></i> 100 Monthly
                  Bookmarks
                </li>
                <li>
                  <i className="fas fa-regular fa-check"></i> Bookmark Email
                  Reviews
                </li>
                <li className="text-gray-500">
                  <i className="fas fa-times mr-1.5"></i> Full Video
                  Transcription
                </li>
              </ul>
              <div className="card-actions justify-center mt-2">
                <a
                  href={basicPlanUrl}
                  className={
                    plan === "basic"
                      ? "btn btn-lg btn-wide justify-between rounded-2xl w-full"
                      : "btn btn-primary btn-lg btn-wide justify-between rounded-2xl w-full"
                  }
                >
                  {plan === "basic" ? "Current Plan" : "Get Started"}
                  <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="card w-full lg:w-1/4 bg-white shadow-xl border">
            <div className="card-body">
              <h3 className="card-title text-xl font-semibold text-gray-800">
                Pro Plan
              </h3>
              {/* <p className="text-gray-600 mt-2">
                Advanced features for power users.
              </p> */}
              {isMonthly ? (
                <p className="text-2xl font-bold text-gray-800 mt-2">
                  $24.99 <span className="text-lg font-normal">/ month</span>
                </p>
              ) : (
                <p className="text-2xl font-bold text-gray-800 mt-2">
                  $19.99{" "}
                  <span className="text-lg font-normal">
                    / month, billed annually
                    {/* ($83.88/year) */}
                  </span>
                </p>
              )}
              <ul className="list-none text-left mt-4 h-full">
                <li>
                  <i className="fas fa-regular fa-check"></i> Unlimited
                  Bookmarks
                </li>
                <li>
                  <i className="fas fa-regular fa-check"></i> Bookmark Email
                  Reviews
                </li>
                <li>
                  <i className="fas fa-regular fa-check"></i> Full Video
                  Transcription
                </li>
                <li>
                  <i className="fas fa-regular fa-check"></i> New features
                </li>
              </ul>
              <div className="card-actions justify-center mt-4">
                <a
                  href={proPlanUrl}
                  className={
                    plan === "pro"
                      ? "btn btn-lg btn-wide justify-between rounded-2xl w-full"
                      : "btn btn-primary btn-lg btn-wide justify-between rounded-2xl w-full"
                  }
                >
                  {plan === "pro" ? "Current Plan" : "Get Started"}
                  <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;

import React, { useEffect, useState } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
//import axios from "axios";
const FlexQuotesContainer = ({ myQuotes }) => {
  const { width } = useWindowDimensions();
  //const height = `${(myQuotes.length / 3) * 150}px`;
  const [totalLength, setTotalLength] = useState(0);

  const [containerHeight, setContainerHeight] = useState(
    totalLength / 3 + (myQuotes.length * 48) / 3
  );
  const [delta, setDelta] = useState(0);

  // const requestQuote = async () => {
  //   const randomQuotes = await axios.get("/api/quotes/");
  //   setMyQuotes(randomQuotes.data);
  // };
  useEffect(() => {
    setDelta(1440 - width);
    setContainerHeight(totalLength / 3 + (myQuotes.length * 68) / 3);
  }, [width]);

  useEffect(() => {
    //requestQuote();
    const totalQuotesWordCount = myQuotes.length
      ? myQuotes.map((quote) => quote.quote.length).reduce((a, b) => a + b)
      : 0;
    setTotalLength(totalQuotesWordCount);
  }, [myQuotes]);
  return (
    <div className="myquotes">
      <h1>MyQuotes {}</h1>
      <div
        style={{ height: (containerHeight + delta).toString() + "px" }}
        className="quote-container"
      >
        {myQuotes.map((quote, index) => {
          return (
            <div className="quote-item" key={index}>
              <p>{quote.quote} </p>
              <b>{quote.author.name}</b>
              <div className="quote-item-footer"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FlexQuotesContainer;

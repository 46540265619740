import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import axios from "axios";
//import Autocomplete from "react-autocomplete";
import { Context } from "../context";
import { parseYouTubeDuration } from "./misc/parseYouTubeDuration";
import { getTimeSince } from "./misc/getTimeSince";
import { parseYouTubeView } from "../components/misc/parseYouTubeView";

const AddAuthorVideo = () => {
  const [, /*selectedAuthorID*/ setSelectedAuthorID] = useState("");
  const [videoId, setVideoId] = useState("");
  const [authorVideos, setAuthorVideos] = useState();

  const [authorObj, setAuthorObj] = useState();
  const [authorName, setAuthorName] = useState("");
  const [authorNames, setAuthorNames] = useState([]);
  const [autocompleteAuthorsList, setAutocompleteAuthorsList] = useState([]);

  const { user } = useContext(Context);
  const [userData /*setUserData*/] = user;
  const { alerts } = useContext(Context);
  const [, /*alert*/ setAlert] = alerts;

  async function fetchData(authorName) {
    try {
      let res = (
        await axios.get(
          `/api/endpoints/youtube?searchQuery=${
            authorName + " full long interview"
          }`,
          {
            headers: { "x-auth-token": userData.token },
          }
        )
      ).data;
      // if (isMounted) setYouTubeVideos(res.items);
      return res.items;
      //console.log(res);
      //if (refreshing) await wait(1500).then(() => setRefreshing(false));
    } catch (err) {
      console.error(err);
    }
  }
  async function fetchVideoData(videos) {
    try {
      let videoIdsWithComma = videos[0].id.videoId;
      for (let i = 1; i < videos.length; i++) {
        videoIdsWithComma += "," + videos[i].id.videoId;
      }

      let res = (
        await axios.get(
          `/api/endpoints/youtube/videos?id=${videoIdsWithComma}`,
          {
            headers: { "x-auth-token": userData.token },
          }
        )
      ).data;
      //console.log(res.items);
      // if (isMounted) setYouTubeVideos(res.items);
      return res.items;
    } catch (err) {
      console.error(err);
    }
  }
  const handleAuthorSelect = async (value) => {
    setAuthorObj(value);
    handleAuthorNameChange(value.name);
    try {
      let authorVids = await fetchData(value.name);
      authorVids = await fetchVideoData(authorVids);
      console.log(authorVids);
      setAuthorVideos(authorVids);
    } catch (err) {
      console.log(err);
    }
  };
  const handleAuthorNameChange = (value) => {
    // const value = e.target.value;
    //console.log(": ", value);
    if (value) setAuthorName(value);
    const autocompleteArr = authorNames.filter(
      (author) => author.name.toLowerCase().includes(value.toLowerCase())
      // author.name.substr(0, value.length).toLowerCase() ===
      //   value.toLowerCase() ||
      // author.name.substr(0, value.length).toLowerCase() ===
      //   value.toLowerCase()
    );
    //console.log(autocompleteArr);
    if (autocompleteArr.length < 10)
      setAutocompleteAuthorsList(autocompleteArr);
    else setAutocompleteAuthorsList([]);
  };

  // const handleAuthorNameOnSelect = (val) => {
  //   handleAuthorNameChange(val);
  // };

  const onSubmit = async (value) => {
    //console.log(typeof e);
    let vidId = videoId;
    if (typeof value === "object") value.preventDefault();
    else vidId = value;

    // find selected author's index
    const authorIndex = authorNames
      .map((author) => author.name.toLowerCase())
      .indexOf(authorName.trim().toLowerCase());
    setSelectedAuthorID(authorIndex);
    //console.log(authorNames[authorIndex]._id);
    let author;
    if (!authorNames[authorIndex]) {
      // setAlert({
      //   message: "Please select authors in autocomplete list",
      //   type: "danger",
      // });
      author = {
        name: authorName,
        _id: null,
      };

      //return;
    } else author = authorNames[authorIndex];
    //console.log(authorNames[authorIndex]);
    console.log(author);
    try {
      const header = {
        headers: {
          "x-auth-token": userData.token,
        },
      };
      // if user adds new quote without signing up,
      //then add those quotes to localstorage and update localquotes state
      if (!userData.token) {
        setAlert({
          message: "Please sign in to add Author Video!",
          type: "danger",
        });
        return;
        // const quotes = [...localQuotes, { quote, author_id }];
        // setLocalQuotes(quotes);
        // localStorage.setItem("quotes", JSON.stringify(quotes));
      } else {
        const res = await axios.post(
          `/api/authorvideos/${vidId}/author/${author._id}`,
          null,
          header
        );
        console.log("Video has been added: ", res.data);
        setVideoId("");
        setAuthorObj();
        setAlert({
          message: "Video has been added",
        });
      }
    } catch (err) {
      console.log("err: ", err.response.data.msg);
    }
  };

  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      try {
        const names = (await axios.get("/api/authors")).data;
        //console.log(names);
        if (isMounted) setAuthorNames(names);
      } catch (err) {
        console.error(err);
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="add-quote">
      <h2>Add Author Video</h2>
      <form onSubmit={onSubmit}>
        <input
          type="text"
          placeholder="Video ID"
          value={videoId}
          onChange={(e) => setVideoId(e.target.value)}
          // required
        />
        <br />
        <label htmlFor="author">Author</label>
        <br />

        <Select
          value={authorObj}
          onChange={(val) => handleAuthorSelect(val)}
          isSearchable
          //isClearable
          onInputChange={(val) => handleAuthorNameChange(val)}
          getOptionLabel={(option) => `${option.name}`}
          getOptionValue={(option) => `${option["name"]}`}
          options={autocompleteAuthorsList}
          closeMenuOnSelect
        />
        <br />

        <input type="submit" value="Add Video" />
      </form>
      <div className="add-author-video-author-videos">
        {authorVideos &&
          authorVideos.map((video, index) => (
            <div className="add-author-video-author-video" key={index}>
              <img
                width="200"
                height="180"
                src={video.snippet.thumbnails.high.url}
                alt="Author YouTube Videos"
              />
              <div className="add-author-video-author-video-duration">
                {parseYouTubeDuration(video.contentDetails.duration)}
              </div>
              <div className="add-author-video-author-video-title">
                <p>
                  {video.snippet.title
                    .replace(/&#39;/g, "'")
                    .replace(/&quot;/g, '"')}
                </p>
              </div>
              <div style={{ color: "#777" }}>
                {/* {video.snippet.channelTitle} ·  */}
                {parseYouTubeView(video.statistics.viewCount)} views ·{" "}
                {getTimeSince(new Date(video.snippet.publishedAt))} ago
              </div>
              <button
                onClick={async () => {
                  console.log(video.id);
                  let authorVids = [...authorVideos];
                  authorVids = authorVids.filter((vid) => vid.id !== video.id);
                  setAuthorVideos(authorVids);
                  setVideoId(video.id);
                  onSubmit(video.id);
                }}
              >
                Add
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AddAuthorVideo;
